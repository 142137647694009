import { CLink } from '@/CLink';
import { IconBtn, PlainBtn } from '@/buttons';
import { ReactComponent as FacebookIcon } from '@a/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '@a/icons/instagram.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import useLocationData from '@s/hooks/useLocationData';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DesktopNav } from './DesktopNavPrimitive';
import { MobNav } from './MobNav';
import { navLinks, siteInfo } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexCenter, flexSpace, px } from './styles/classes';

const Navbar = styled.header<{ scrolled: boolean }>`
    ${px};
    padding-top: 16px;
    padding-bottom: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-right: var(--removed-body-scroll-bar-size);
    z-index: 100;
    background-color: ${({ theme }) => theme.colors.gray5};
    transition: padding 0.2s ease-in-out;

    .desktop-nav-dropdown {
        top: ${({ scrolled }) =>
            scrolled ? ' calc((1rem * 1.5) + 16px)' : ' calc((1rem * 1.5) + 32px)'};
    }

    > div {
        ${flexSpace};
        max-width: 1300px;
        margin: 0 auto;

        > div:last-of-type {
            ${flexCenter};
            justify-content: flex-end;
        }
    }

    @media (min-width: 1280px) {
        padding: 48px;
        padding-bottom: 32px;

        > div {
            display: block;

            > div {
                :first-of-type {
                    margin-bottom: 64px;
                    transition: margin-bottom 0.2s ease-in-out;
                }

                :last-of-type {
                    display: block;
                }
            }
        }

        ${({ scrolled }) =>
            scrolled &&
            css`
                padding-top: 16px;
                padding-bottom: 16px;

                > div {
                    > div:first-of-type {
                        margin-bottom: 32px;

                        > a {
                            max-height: 40px;

                            > .gatsby-image-wrapper {
                                transform: scale(0.555) translateY(-24px);
                            }
                        }
                    }
                }
            `};
    }
`;

const Top = styled.div`
    > div {
        display: none;
    }

    @media (min-width: 1280px) {
        ${flexSpace};

        > div {
            display: flex;
            gap: 24px;
        }
    }
`;

const HomeLink = styled(CLink)`
    transition: opacity 0.3s ease-in-out;
    flex-shrink: 0;

    > .gatsby-image-wrapper {
        transition:
            max-height 0.2s ease-in-out,
            transform 0.2s ease-in-out;
    }

    :hover {
        opacity: 0.7;
    }

    :focus-visible {
        opacity: 0.7;
    }

    @media (max-width: 1279px) {
        > .gatsby-image-wrapper {
            width: 177px;
            height: 22px;
        }
    }
`;

const DesktopNavMenu = styled(DesktopNav)`
    width: 100%;
    display: none;

    @media (min-width: 1280px) {
        display: block;
    }
`;

const menuStyles = css`
    justify-content: space-between;

    li {
        line-height: 150%;

        @media (max-width: 1720px) {
            :last-of-type > div:last-of-type {
                right: 0;
                left: unset;
            }
        }
    }
`;

const contentStyles = css`
    overflow: hidden;
    position: absolute;
    min-width: 328px;
    width: max-content;
    z-index: 100;
    border: 1px solid ${theme.colors.gray2};
    background-color: ${theme.colors.gray1};
    border-top: none;

    > ul {
        max-height: calc(100vh - 240px);
        overflow-y: auto;
    }
`;

const MobCallBtn = styled(PlainBtn)`
    width: 24px;
    height: 24px;
    margin-right: 24px;

    @media (min-width: 1280px) {
        display: none;
    }
`;

const Observe = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    top: 96px;
    left: 0;
`;

export const HomeLogo = () => (
    <HomeLink to="/">
        <StaticImage
            src="../../../assets/images/layout/logo.png"
            alt="my vanity aesthetics med spa"
            width={576}
            height={72}
            loading="eager"
        />
    </HomeLink>
);

// link can be /meet-the-doctors/#dr-herbert or #dr-herbert
export const scrollToSection = (link: string) => {
    const fullLink = typeof link === 'string' ? link.split('#') : '';
    const element = document.querySelector(`#${fullLink[1] || ''}`);
    if (!element) {
        navigate(fullLink[0], {
            state: {
                tag: `#${fullLink[1] || ''}`,
            },
        });
    }
    const y = element && element.getBoundingClientRect().top + window.scrollY - 76;
    window.scroll({
        behavior: 'smooth',
        top: y || 0,
    });
};

export const Nav = () => {
    const [dialogsClosed, setDialogsClosed] = useState(false);
    const closeAllDialogs = () => setDialogsClosed(open => !open);

    const { title } = useLocationData();

    const [scrolled, setScrolled] = useState(title ? true : false);

    const observeRef = useRef<HTMLElement | null>(null);

    const visible = useIntersectionObserver(observeRef, {});

    useEffect(() => {
        const timeout = window.setTimeout(() => {
            setScrolled(title ? true : !visible);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [visible, title]);

    return (
        <Fragment>
            <Navbar scrolled={scrolled}>
                <div>
                    <Top>
                        <div>
                            <IconBtn as={CLink} to={siteInfo.social.facebook}>
                                <FacebookIcon />
                            </IconBtn>
                            <IconBtn as={CLink} to={siteInfo.social.instagram}>
                                <InstagramIcon />
                            </IconBtn>
                        </div>
                        <HomeLogo />
                        <div>
                            {/* temporary */}
                            <span style={{ width: '56px' }} />
                            {/* <IconBtn as={CLink} to="/appointment/" background>
                        <AppointmentIcon />
                    </IconBtn> */}
                            <IconBtn as={CLink} to={siteInfo.phone.link} border>
                                <PhoneIcon />
                            </IconBtn>
                        </div>
                    </Top>
                    <div>
                        <DesktopNavMenu
                            menuStyles={menuStyles}
                            contentStyles={contentStyles}
                            links={navLinks}
                        />
                        <MobCallBtn as={CLink} to={siteInfo.phone.link}>
                            <PhoneIcon />
                        </MobCallBtn>
                        <MobNav dialogsClosed={dialogsClosed} closeAllDialogs={closeAllDialogs} />
                    </div>
                </div>
            </Navbar>
            <Observe ref={observeRef} />
        </Fragment>
    );
};
